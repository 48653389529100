var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"IZ-select",staticClass:"IZ-select",attrs:{"tabindex":_vm.disableSearch ? 0 : -1},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }return _vm.onSelectByArrow($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }return _vm.onSelectByArrow($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onEnter($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.setBlured($event)}],"mousedown":_vm.onClick,"focus":_vm.setFocused}},[_c('div',{staticClass:"IZ-select__input-wrap"},[_vm._t("input-before"),_c('div',{ref:"IZ-select__input",class:{
        'IZ-select__input': true,
        'IZ-select__input--focused': _vm.focused,
        'IZ-select__input--has-menu': _vm.hasMenu,
        'IZ-select__input--has-error': _vm.hasError,
        'IZ-select__input--successful': _vm.successful,
        'IZ-select__input--selection-slot': _vm.showSelectionSlot,
        'IZ-select__input--disabled': _vm.disabled,
        'IZ-select__input--readonly': _vm.readonly
      },style:(_vm.inputStyles)},[_vm._t("input-start"),(_vm.showSelectionSlot)?_vm._t("selection",null,{"item":_vm.selectedItem}):_vm._e(),_c('input',_vm._b({ref:"IZ-select__input-for-text",class:_vm.inputForTextClass,style:(_vm.inputForTextStyles),attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disableSearch || _vm.disabled,"readonly":_vm.readonly,"tabindex":_vm.disableSearch ? -1 : 0,"type":"text","role":"combobox","autocomplete":"new-password"},domProps:{"value":_vm.inputValue},on:{"keyup":_vm.onSearchKeyUp,"keydown":_vm.onSearchKeyDown,"input":_vm.onSearch,"mousedown":_vm.onClick,"focus":function($event){return _vm.setFocused(true)}}},'input',_vm.inputElCustomAttributes,false)),_vm._t("input-end")],2),_vm._t("input-after")],2),_c('transition',{attrs:{"name":"fade"}},[(_vm.hasMenu)?_c('div',{ref:"IZ-select__menu",class:{
        'IZ-select__menu': true,
        'IZ-select__menu--disable-search': _vm.disableSearch
      },style:(_vm.menuDynamicStyles)},[_vm._t("before-items-fixed"),_c('div',{ref:"IZ-select__menu-items",staticClass:"IZ-select__menu-items",style:({
          'max-height': _vm.menuItemsMaxHeight
        }),on:{"scroll":_vm.onScroll}},[_vm._t("before-items",[_c('div',{staticStyle:{"height":"8px"}})]),_vm._l((_vm.itemsComputed),function(item,i){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(i < _vm.scrollItemsLimitCurrent || (_vm.arrowsIndex && i <= _vm.arrowsIndex)),expression:"i < scrollItemsLimitCurrent || (arrowsIndex && i <= arrowsIndex)"}],key:'IZ-item-' + i,ref:"items",refInFor:true,class:{
            'IZ-select__item': true,
            'IZ-select__item--selected': _vm.isItemSelected(item)
          },on:{"click":function($event){return _vm.onClickSelectItem(item)}}},[_vm._t("item",[_c('span',[_vm._v("\n              "+_vm._s(_vm.getItemText(item))+"\n            ")])],{"item":item})],2)}),(!_vm.itemsComputed.length && !_vm.loading)?_c('div',{staticClass:"IZ-select__no-data"},[_vm._t("no-data",[_vm._v("\n            No data available\n          ")])],2):_vm._e(),_vm._t("after-items",[_c('div',{staticStyle:{"height":"8px"}})])],2),_vm._t("after-items-fixed"),_c('div',{staticStyle:{"position":"absolute","top":"0","left":"0","right":"0"}},[_vm._t("before-items-fixed-absolute")],2),_c('div',{staticStyle:{"position":"absolute","bottom":"0","left":"0","right":"0"}},[_vm._t("after-items-fixed-absolute")],2)],2):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.errorMessage),expression:"errorMessage"}],staticClass:"IZ-select__error"},[_vm._t("error",[_vm._v("\n        "+_vm._s(_vm.errorMessage)+"\n      ")],{"errorMessage":_vm.errorMessage})],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }